import "../styles/Index.scss"
import "../styles/Navbar.scss"
import "../styles/App.scss"
import "../styles/About.scss"
import "../styles/Contact.scss"
import "../styles/ImageCard.scss"
import { Fragment } from "react"
import Head from "next/head"

function MyApp({ Component, pageProps }) {
    return (
      <Fragment>
        <Head>
          <title>Nando Machado Photography</title>
          <meta 
            name="description" 
            content="Nando Machado's photography portfolio featuring landscape, travel and environmental work, both published and unpublished. Author: Nando Machado. All rights reserved"
          />
        </Head>
        <Component {...pageProps} />
      </Fragment>
    )
  }

export default MyApp